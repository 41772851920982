//note: để dấu ":" trong album/:title/:pid để cho react-router-dom nó hiểu đó là params
const path = {
    PUBLIC: "/*",
    HOME: "",
    LOGIN: "login",
    STAR: "*",
    MY_MUSIC: "mymusic",
    ALBUM__TITLE__PID: "album/:title/:pid",
    PLAYLIST__TITLE__PID: "playlist/:title/:pid",
    WEEKRANK__TITLE__PID: "zing-chart-tuan/:title/:pid",
    ZING_CHART: "zing-chart",
    SEARCH: "tim-kiem",
    ALL: "tat-ca",
    SONG: "bai-hat",
    HOME__SINGER: ":singer",
    HOME_ARTIST__SINGER: "nghe-si/:singer",
    PLAYLIST_SEARCH: "playlist",
};

export default path;
