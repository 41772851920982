const actionTypes = {
    GET_HOME: "GET_HOME",
    SET_CUR_SONG_ID: "SET_CUR_SONG_ID",
    LOADING: "LOADING",
    SET_CUR_SONG_DATA: "SET_CUR_SONG_DATA",
    SET_CUR_ALBUM_ID: "SET_CUR_ALBUM_ID",
    SET_RECENT: "SET_RECENT",
    CHECK_LOVE: "CHECK_LOVE",
    CURRENT_WIDTH: "CURRENT_WIDTH",
    //music
    PLAY: "PLAY",
    SET_ALBUM: "SET_ALBUM",
    PLAYLIST: "PLAYLIST",
    SEARCH: "SEARCH",
};

export default actionTypes;
